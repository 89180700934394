import ready from '../utils/ready';
const Flickity = require('flickity');

ready().then(() => {
  // Init carousel
  const flkty = new Flickity('.carousel', {
    autoPlay: true,
    lazyLoad: true,
    prevNextButtons: false,
    wrapAround: true
  });

  // On home page only: resize navbar logo on scroll down
  var logo = document.getElementById("logo");
  logo.className = 'largeLogo';
  var changeLogoOn = 52;
  var didResize = false;

  function resizeLogo() {
    var scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (!didResize && scroll > changeLogoOn) {
      logo.className = 'smallLogo';
      didResize = true;
    } else if (didResize && scroll <= changeLogoOn) {
      logo.className = 'largeLogo';
      didResize = false;
    }
  }

  window.addEventListener('scroll', function (event) {
    setTimeout(resizeLogo, 250);
  }, false);
});
